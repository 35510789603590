import React from "react";
import { withAuthenticationRequired } from "react-oidc-context";
import { Navigate } from "react-router-dom";
import RedirectToLoginPage from "components/RedirectToLoginPage";

const AuthCallback = () => {
  return <Navigate to="/" replace />;
};

export default withAuthenticationRequired(AuthCallback, {
  OnRedirecting: () => <RedirectToLoginPage />
});
