import React, { FC } from "react";
import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";


interface AbsoluteLoaderProps {
    loading: boolean;
}

const AbsoluteLoader: FC<AbsoluteLoaderProps> = ({ loading }) => {
    if (loading) return (
        <LoaderContainer>
            <CircularProgress />
        </LoaderContainer>
    )
    return null;
}

export default AbsoluteLoader;


const LoaderContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255,255,255,0.8);
    z-index: 9;
`;
