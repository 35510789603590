import React, { FC, ReactNode, useCallback, useEffect, useMemo } from "react";
import { ApolloClient, ApolloProvider, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import cache from "config/cache";

import { getOIDCUser } from "./auth";
import { useAuth } from "react-oidc-context";

const useClient = () => {
  return useMemo(() => {
    const httpLink = createHttpLink({
      uri: process.env.GRAPHQL_API_URL,
    });

    const authLink = setContext((_, { headers }) => {
      const user = getOIDCUser();
      const token = user?.access_token;
      if (token) {
        return {
          headers: {
            ...headers,
            authorization: `Bearer ${token}`,
          },
        };
      } else {
        return { headers };
      }
    });

    return new ApolloClient({
      link: authLink.concat(httpLink),
      cache,
    });
  }, []);
};

export const CustomApolloProvider: FC<{ children: ReactNode }> = (props) => {
  const { events, signoutSilent } = useAuth();
  const client = useClient();

  const handleAutoLogout = useCallback(async () => {
    await signoutSilent();
    window.location.href = '/';
  }, [signoutSilent]);

  useEffect(() => {
    events.addUserSignedOut(handleAutoLogout);
    return () => {
      events.removeUserSignedOut(handleAutoLogout);
    };
  }, [events, handleAutoLogout]);

  return <ApolloProvider client={client} {...props} />;
};

export default CustomApolloProvider;
