import React, { FC } from "react";
import {
  AppBar,
  AppBarProps,
  Avatar,
  Box,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import ProfileDropdown from "./ProfileDropdown";
import imgLogo from "assets/images/logo.png";

const HeaderWithOutSideMenu: FC<AppBarProps> = ({ ...props }) => {
  return (
    <AppBar position="fixed" {...props}>
      <Toolbar variant="dense">
        <Stack direction="row" alignItems="center">
          <Avatar
            src={imgLogo}
            alt=""
            variant="rounded"
            sx={{ marginRight: { xs: 0.5 }, backgroundColor: "transparent" }}
          />
          <Typography
            variant="h4"
            sx={{ fontSize: "1rem", fontWeight: 500 }}
            noWrap
          >
            Admin Panel
          </Typography>
        </Stack>
        <Box sx={{ flexGrow: 0, ml: "auto" }}>
          <ProfileDropdown />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default HeaderWithOutSideMenu;
