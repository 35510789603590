import React, { lazy, Suspense } from "react";
import { withAdminRoleRequired } from "config/auth";
import { keyRoleMap } from "config/side-menu-items";
import { Route, Routes } from "react-router-dom";

const UserEngagementSummary = lazy(() => import("./user-engagement"));
const PostSummary = lazy(() => import("./PostSummary"));
const CommentSummary = lazy(() => import("./CommentSummary"));
const ReplySummary = lazy(() => import("./ReplySummary"));
const UserSessionSummary = lazy(() => import("./UserSessionSummary"));
const PollSummary = lazy(() => import("./poll"));
const PowerUserNoOfPowerUserBadgesReceivedByUser = lazy(
  () => import("./power-user/NoOfPowerUserBadgesReceivedByUser")
);
const PowerUsersFeedbacks = lazy(
  () => import("./power-user/PowerUsersFeedbacks")
);
const PowerUserMISStreakMissedUsers = lazy(
  () => import("./power-user/StreakMissedUsers")
);
const PowerUserWeeklyChart = lazy(() => import("./power-user/WeeklyChart"));
const DailySignupSummary = lazy(() => import("./DailySignupSummary"));
const CreatorsSummary = lazy(() => import("./CreatorsSummary"));
const WeeklyProgress = lazy(() => import("./WeeklyProgress/WeeklyProgress"));
const MISNPS = lazy(() => import("./nps/NpsSummary"));

const MISSettings = () => {
  return (
    <Routes>
      <Route
        path="nps"
        element={
          <Suspense>
            <MISNPS />
          </Suspense>
        }
      />
      <Route
        path="session-summary"
        element={
          <Suspense fallback={<></>}>
            <UserSessionSummary />
          </Suspense>
        }
      />
      <Route
        path="user-engagement-summary"
        element={
          <Suspense fallback={<></>}>
            <UserEngagementSummary />
          </Suspense>
        }
      />
      <Route
        path="daily-signup-summary"
        element={
          <Suspense fallback={<></>}>
            <DailySignupSummary />
          </Suspense>
        }
      />
      <Route
        path="post-summary"
        element={
          <Suspense fallback={<></>}>
            <PostSummary />
          </Suspense>
        }
      />
      <Route
        path="comment-summary"
        element={
          <Suspense fallback={<></>}>
            <CommentSummary clubs={[]} />
          </Suspense>
        }
      />
      <Route
        path="reply-summary"
        element={
          <Suspense fallback={<></>}>
            <ReplySummary />
          </Suspense>
        }
      />

      <Route
        path="poll-summary"
        element={
          <Suspense fallback={<></>}>
            <PollSummary />
          </Suspense>
        }
      />
      <Route
        path="creators-summary"
        element={
          <Suspense fallback={<></>}>
            <CreatorsSummary />
          </Suspense>
        }
      />
      <Route path="power-user/*">
        <Route
          path="badges"
          element={
            <Suspense fallback={<></>}>
              <PowerUserNoOfPowerUserBadgesReceivedByUser />
            </Suspense>
          }
        />
        <Route
          path="feedbacks"
          element={
            <Suspense fallback={<></>}>
              <PowerUsersFeedbacks />
            </Suspense>
          }
        />
        <Route
          path="weekly-chart"
          element={
            <Suspense fallback={<></>}>
              <PowerUserWeeklyChart />
            </Suspense>
          }
        />
        <Route
          path="weekly-progress"
          element={
            <Suspense fallback={<></>}>
              <WeeklyProgress />
            </Suspense>
          }
        />
        <Route
          path="streak-missed-users"
          element={
            <Suspense fallback={<></>}>
              <PowerUserMISStreakMissedUsers />
            </Suspense>
          }
        />
      </Route>
    </Routes>
  );
};

export default withAdminRoleRequired(MISSettings, {
  roles: keyRoleMap["MIS"],
});
