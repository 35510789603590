import React, { FC } from "react";
import { Outlet } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "./components/Header";
import SideMenu from "./components/SideMenu";
import { withAuthenticationRequired } from "react-oidc-context";
import { withAdminAuthRequired } from "config/auth";
import RedirectToLoginPage from "components/RedirectToLoginPage";

const drawerWidth = 240;

const MainLayout: FC = () => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const matches = useMediaQuery("(min-width:961px)");
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container = window?.document?.body;

  return (
    <Box sx={{ display: "flex" }}>
      <Header handleDrawerToggle={handleDrawerToggle} />
      <Drawer
        container={matches ? null : container}
        variant={matches ? "permanent" : "temporary"}
        open={mobileOpen || matches}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
      >
        <SideMenu handleDrawerToggle={handleDrawerToggle} />
      </Drawer>
      <Box
        component="main"
        sx={
          matches
            ? {
                width: { sm: `calc(100% - ${drawerWidth}px)` },
                ml: { sm: `${drawerWidth}px` },
              }
            : { width: { xs: "100%" }, ml: { sm: 0 } }
        }
      >
        <Toolbar variant="dense" />
        <Outlet />
      </Box>
    </Box>
  );
};

export default withAuthenticationRequired(withAdminAuthRequired(MainLayout), {
  OnRedirecting: () => <RedirectToLoginPage />,
});
