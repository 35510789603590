import React from "react";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { Toaster } from "react-hot-toast";
import { ConfirmProvider } from "material-ui-confirm";
import { BrowserRouter } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Theme from "theme";
import { AuthProvider } from "config/auth";
import Routes from "Routes";
import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isBetweenPlugin from "dayjs/plugin/isBetween";
import isoWeek from "dayjs/plugin/isoWeek";

dayjs.extend(updateLocale);
dayjs.extend(isBetweenPlugin);
dayjs.extend(advancedFormat);
dayjs.extend(isoWeek);

dayjs.updateLocale("en", {
  weekStart: 1,
});

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <Theme>
          <ConfirmProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <HelmetProvider>
                <Routes />
              </HelmetProvider>
            </LocalizationProvider>
            <Toaster />
          </ConfirmProvider>
        </Theme>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);
