export const getTitle = (pathname: string): string => {
  if (new RegExp("/users/search-users").test(pathname))
    return "Users / Search Users";
  if (new RegExp("/users/green-tick-users").test(pathname))
    return "Users / Green Tick Users";
  if (new RegExp("/users/blue-tick-users").test(pathname))
    return "Users / Blue Tick Users";
  if (new RegExp("/users/yellow-tick-users").test(pathname))
    return "Users / Yellow Tick Users";
  if (new RegExp("/mis/user-engagement-summary").test(pathname))
    return "MIS / User Engagement Summary";
  if (new RegExp("/mis/daily-signup-summary").test(pathname))
    return "MIS / Daily Signup Summary";
  if (new RegExp("/mis/post-summary").test(pathname))
    return "MIS / Post Summary";
  if (new RegExp("/mis/comment-summary").test(pathname))
    return "MIS / Comment Summary";
  if (new RegExp("/mis/reply-summary").test(pathname))
    return "MIS / Reply Summary";
  if (new RegExp("/mis/session-summary").test(pathname))
    return "MIS / Session Summary";
  if (new RegExp("/mis/poll-summary").test(pathname))
    return "MIS / Poll Summary";
  if (new RegExp("/mis/creators-summary").test(pathname))
    return "MIS / Creators Summary";
  if (new RegExp("/reports/all-reports").test(pathname))
    return "Report / All Reports";
  if (new RegExp("/reports/reports-summary").test(pathname))
    return "Report / Report Summary";
  if (new RegExp("/reports/reports-action-history").test(pathname))
    return "Report / Report Action History";
  if (new RegExp("/clubs/all-clubs").test(pathname)) return "Clubs / All Clubs";
  if (new RegExp("/clubs/requested-clubs").test(pathname))
    return "Clubs / Requested Clubs";
  if (new RegExp("/clubs/create-club").test(pathname))
    return "Clubs / Create New Club";
  if (new RegExp("/onboarding/hashtag-categorization").test(pathname))
    return "Onboarding / Hashtag Categorization";
  if (new RegExp("/onboarding/tick-users-categorization").test(pathname))
    return "Onboarding / Tick Users Categorization";
  if (new RegExp("/activities").test(pathname)) return "Activities";
  if (new RegExp("/whitelisting").test(pathname)) return "Whitelisting";
  if (new RegExp("/feedbacks").test(pathname)) return "Feedbacks";
  if (new RegExp("/suspected").test(pathname)) return "Suspected";
  if (new RegExp("/summaries").test(pathname)) return "Summaries";
  if (new RegExp("/reports").test(pathname)) return "Reports";
  if (new RegExp("/clubs/categories").test(pathname)) return "Club Categories";
  if (new RegExp("/clubs").test(pathname)) return "Clubs";
  if (new RegExp("/users").test(pathname)) return "Users";
  if (new RegExp("/notifications").test(pathname)) return "Notifications";
  if (new RegExp("/settings").test(pathname)) return "Settings";
  if (new RegExp("/mom").test(pathname)) return "Member of the Month";
  if (new RegExp("/features/platform").test(pathname))
    return "Platform Features";
  if (new RegExp("/features/club").test(pathname)) return "Club Features";
  if (new RegExp("/events").test(pathname)) return "Events";
  if (new RegExp("/nps").test(pathname)) return "NPS";
  if (new RegExp("/push-notifications").test(pathname))
    return "Push Notifications";
  if (new RegExp("/jobs").test(pathname)) return "CRON / Schedule Jobs";
  if (new RegExp("/productTour").test(pathname)) return "Product Tour";
  if (new RegExp("/interstitials").test(pathname)) return "Smart  Feed";
  if (new RegExp("/ab-testing").test(pathname)) return "Alpha-Beta Testing";
  if (new RegExp("/badge-feedback-questions").test(pathname))
    return "Badge Feedback Questions";
  if (new RegExp("/badge-system").test(pathname)) return "Badge System";
  if (new RegExp("/badge-popups").test(pathname)) return "Badge Popups";
  if (new RegExp("/clubs/categories").test(pathname)) return "Club Categories";
  if (new RegExp("/priority-profiles").test(pathname))
    return "Priority Profiles";
  if (new RegExp("/whats-new").test(pathname)) return "What's New";
  return "Dashboard";
};

export const convertPathToReadable = (path: string): string =>
  path
    .split("/")
    .filter(Boolean)
    .map((segment) =>
      segment
        .split("-")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
    )
    .join(" > ");
