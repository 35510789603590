import { Roles } from "constants/roles";

const menuItems: MenuItem[] = [
  {
    key: "Dashboard",
    text: "Dashboard",
    path: "/",
    roles: [
      Roles.SocialMarketing,
      Roles.SocialModerator,
      Roles.SocialAdmin,
      Roles.SocialMIS,
      Roles.SocialTechnicalOperator,
    ],
  },
  {
    key: "Moderator Settings",
    text: "Moderator Settings",
    roles: [Roles.SocialAdmin, Roles.SocialModerator],
    children: [
      {
        key: "Reports",
        text: "Reports",
        children: [
          {
            text: "Report Summary",
            path: "/moderator-settings/reports/summaries",
          },
          { text: "All Reports", path: "/moderator-settings/reports/all" },
          {
            text: "Report Action History",
            path: "/moderator-settings/reports/action-history",
          },
        ],
      },
      {
        key: "Users",
        text: "Users",
        children: [
          {
            text: "Search Users",
            path: "/moderator-settings/users/search",
          },
          {
            text: "Blue Tick Users",
            path: "/moderator-settings/users/blue-tick",
          },
          {
            text: "Green Tick Users",
            path: "/moderator-settings/users/green-tick",
          },
        ],
      },
    ],
  },
  {
    key: "MIS",
    text: "MIS",
    roles: [Roles.SocialAdmin, Roles.SocialMIS],
    children: [
      { text: "Session Summary", path: "/mis/session-summary" },
      { text: "User Engagement Summary", path: "/mis/user-engagement-summary" },
      { text: "Daily Signup Summary", path: "/mis/daily-signup-summary" },
      { text: "Post Summary", path: "/mis/post-summary" },
      { text: "Comment Summary", path: "/mis/comment-summary" },
      { text: "Reply Summary", path: "/mis/reply-summary" },
      { text: "Poll Summary", path: "/mis/poll-summary" },
      { text: "Creators Summary", path: "/mis/creators-summary" },
      {
        text: "Power User",
        children: [
          {
            text: "Badges",
            path: "/mis/power-user/badges",
          },
          {
            text: "Feedbacks",
            path: "/mis/power-user/feedbacks",
          },
          {
            text: "Weekly Chart",
            path: "/mis/power-user/weekly-chart",
          },
          {
            text: "Weekly Progress",
            path: "/mis/power-user/weekly-progress",
          },
          {
            text: "Streak Missed Users",
            path: "/mis/power-user/streak-missed-users",
          },
        ],
      },
      {
        key: "NPS",
        text: "NPS",
        path: "/mis/nps",
      },
    ],
  },
  {
    key: "Marketing Settings",
    text: "Marketing Settings",
    roles: [Roles.SocialAdmin, Roles.SocialMarketing],
    children: [
      {
        key: "Activities",
        text: "Activities",
        path: "/marketing-settings/activities",
      },
      {
        key: "Events",
        text: "Events",
        path: "/marketing-settings/events",
      },
      {
        key: "Promotion",
        text: "Promotion",
        path: "/marketing-settings/promotion",
      },
      {
        key: "Push Notifications",
        text: "Push Notifications",
        path: "/marketing-settings/push-notifications",
      },
      {
        key: "Smart Feed",
        text: "Smart Feed",
        path: "/marketing-settings/smart-feed",
      },
    ],
  },
  {
    key: "Developer Settings",
    text: "Developer Settings",
    roles: [Roles.SocialAdmin, Roles.SocialTechnicalOperator],
    children: [
      {
        key: "AB Testing",
        text: "AB Testing",
        path: "/developer-settings/ab-testing",
      },
      {
        key: "Badge System",
        text: "Badge System",
        path: "/developer-settings/badge-system",
      },
      {
        key: "Clubs",
        text: "Clubs",
        path: "/developer-settings/clubs",
      },
      {
        key: "CRON / Schedule Jobs",
        text: "CRON / Schedule Jobs",
        path: "/developer-settings/jobs/schedule-jobs",
        children: [
          { text: "Schedule Jobs", path: "/developer-settings/jobs/schedule-jobs" },
          { text: "Pending Schedule Jobs", path: "/developer-settings/jobs/pending-schedule-jobs" },
          { text: "Job History", path: "/developer-settings/jobs/job-history" },
        ],
      },
      {
        key: "External Resource",
        text: "External Resource",
        path: "/developer-settings/external-resource",
      },
      {
        key: "Features",
        text: "Features",
        children: [
          { text: "Platform", path: "/developer-settings/features/platform" },
          { text: "Club", path: "/developer-settings/features/club" },
        ],
      },
      {
        key: "Onboarding",
        text: "Onboarding",
        children: [
          {
            text: "Hashtag Categorization",
            path: "/developer-settings/onboarding/hashtag-categorization",
          },
          {
            text: "Tick Users Categorization",
            path: "/developer-settings/onboarding/tick-users-categorization",
          },
        ],
      },
      {
        key: "Priority Profiles",
        text: "Priority Profiles",
        path: "/developer-settings/priority-profiles",
      },
      {
        key: "Product Tour",
        text: "Product Tour",
        path: "/developer-settings/product-tour",
      },
      {
        key: "Tick Management",
        text: "Tick Management",
        children: [
          {
            text: "Search Users",
            path: "/developer-settings/users/search-users",
          },
          {
            text: "Blue Tick Users",
            path: "/developer-settings/users/blue-tick-users",
          },
          {
            text: "Green Tick Users",
            path: "/developer-settings/users/green-tick-users",
          },
        ],
      },
      {
        key: "Whats New",
        text: "Whats New",
        path: "/developer-settings/whats-new",
      },
    ],
  },
];

export default menuItems;

export const keyRoleMap = menuItems?.reduce(
  (a, c) => ({ ...a, [c.key]: c.roles }),
  {} as Record<Key, Array<Roles>>
);
