import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";

const Reports = React.lazy(() => import("./Reports"));
const ReportDetails = React.lazy(() => import("./ReportDetails"));
const AllReports = React.lazy(() => import("./AllReports"));
const ReportActionHistory = React.lazy(() => import("./ReportActionHistory"));
const ViewReport = React.lazy(() => import("./ViewReport"));
const ActionHistory = React.lazy(() => import("./ActionHistory"));
const ReportHistory = React.lazy(() => import("./ReportHistory"));

export const ReportRoutes = () => {
  return (
    <Routes>
      <Route
        path="action-history"
        element={
          <Suspense fallback={<></>}>
            <ReportActionHistory />
          </Suspense>
        }
      />
      <Route
        path="all"
        element={
          <Suspense fallback={<></>}>
            <AllReports />
          </Suspense>
        }
      />
      <Route
        path="summaries"
        element={
          <Suspense fallback={<></>}>
            <Reports />
          </Suspense>
        }
      />
      <Route
        path=":reportId"
        element={
          <Suspense fallback={<></>}>
            <ReportDetails />
          </Suspense>
        }
      >
        <Route index element={<ViewReport />} />
        <Route path="report-history" element={<ReportHistory />} />
        <Route path="action-history" element={<ActionHistory />} />
      </Route>
    </Routes>
  );
};

export default ReportRoutes;
