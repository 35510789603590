import React from 'react'
import { css, Global } from '@emotion/react'

const GlobalStyles = () => {
    return (
        <Global styles={css`
                html, body, #root {
                    width: 100%;
                    height: 100%;
                }
            `}
        />
    )
}

export default GlobalStyles;