import React, { useMemo } from "react";
import { FC, PropsWithChildren } from "react";
import { AuthProvider, AuthProviderProps } from "react-oidc-context";
import { WebStorageStateStore, User } from "oidc-client-ts";
import { jwtDecode, JwtPayload } from "jwt-decode";
import { isArray } from "lodash-es";

const oidcConfig: AuthProviderProps = {
  redirect_uri: `${window.location.origin}/auth/callback`,
  response_type: "code",
  scope: "openid profile email offline_access social.app",
  post_logout_redirect_uri: `${window.location.origin}`,
  silent_redirect_uri: `${window.location.origin}/auth/callback`,
  revokeTokensOnSignout: true,
  stateStore: new WebStorageStateStore({ store: localStorage }),
  userStore: new WebStorageStateStore({ store: localStorage }),
  stopCheckSessionOnError: false,
  automaticSilentRenew: true,
  monitorSession: true,
  authority: process.env.OIDC_AUTHORITY as string,
  client_id: process.env.OIDC_CLIENT_ID as string,
};

export const OIDCAuthProvider: FC<PropsWithChildren> = ({ children }) => {
  return <AuthProvider {...oidcConfig}>{children}</AuthProvider>;
};

export const getOIDCUser = () => {
  const oidcStorage = localStorage.getItem(
    `oidc.user:${process.env.OIDC_AUTHORITY}:${process.env.OIDC_CLIENT_ID}`
  );

  if (!oidcStorage) return null;

  return User.fromStorageString(oidcStorage);
};

export const useGetRoles = () =>
  useMemo<Array<string>>(() => {
    const user = getOIDCUser();
    if (user?.access_token) {
      const decoded = jwtDecode<JwtPayload & { role: Array<string> | string }>(
        user?.access_token
      );
      return decoded.role
        ? isArray(decoded.role)
          ? decoded.role
          : [decoded.role]
        : [];
    }
    return [];
  }, []);
