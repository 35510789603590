import React, { FC, ReactNode } from "react";
import { ThemeProvider } from "@emotion/react";
import { responsiveFontSizes } from "@mui/material/styles";
import { CssBaseline, ThemeProvider as MUIThemeProvider } from "@mui/material";
import lightTheme from "theme/light.theme";
import GlobalStyles from "theme/GlobalStyles";

interface ThemeProps {
    children: ReactNode;
}

const Theme: FC<ThemeProps> = ({ children }) => {
    const theme = responsiveFontSizes(lightTheme);
    console.log({ theme });
    return (
        <MUIThemeProvider theme={theme}>
            <ThemeProvider theme={theme}>
                <CssBaseline enableColorScheme />
                {children}
                <GlobalStyles />
            </ThemeProvider>
        </MUIThemeProvider>
    );
};

export default Theme;
