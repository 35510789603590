import React from "react";
import { Paper, Typography } from "@mui/material"
import styled from "@emotion/styled";

const NoDataFound = () => {
    return (
        <Container elevation={0}>
            <Typography>No Data found</Typography>
        </Container>
    );
};

export default NoDataFound;

const Container = styled(Paper)`
  background-color: #fff;
  margin: 36px auto;
  width: 200px;
  padding: 24px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
