import React from "react";
import { withAdminRoleRequired } from "config/auth";
import { keyRoleMap } from "config/side-menu-items";
import { Route, Routes } from "react-router-dom";

import Reports from "./reports";
import Users from "./users";

const ModeratorSettings = () => {
  return (
    <Routes>
      <Route path="reports/*" element={<Reports />} />
      <Route path="users/*" element={<Users />} />
    </Routes>
  );
};

export default withAdminRoleRequired(ModeratorSettings, {
  roles: keyRoleMap["Moderator Settings"],
});
