import React, { useState } from "react";
import {
  Avatar,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Button,
} from "@mui/material";
import { useAuth } from "react-oidc-context";
import { useApolloClient } from "@apollo/client";
import { ArrowDropDown } from "@mui/icons-material";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";

const ProfileDropdown = () => {
  const navigate = useNavigate();
  const { user, signoutRedirect } = useAuth();
  const client = useApolloClient();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout: React.MouseEventHandler<HTMLLIElement> = async (e) => {
    e.preventDefault();
    await signoutRedirect({
      id_token_hint: user?.id_token,
    });
    await client.resetStore();
    navigate("/", { replace: true });
  };

  const open = Boolean(anchorEl);

  return (
    <Container>
      <Button
        variant="text"
        id={"profileMenu"}
        aria-controls={open ? "profileMenu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        size="small"
        endIcon={<ArrowDropDown />}
        sx={{
          ".MuiButton-endIcon": {
            marginLeft: 0,
          },
        }}
      >
        <Avatar
          src={
            "https://d1g74pkr7flvuz.cloudfront.net/Website/Images/download02032022164647.png"
          }
          sx={{ width: 32, height: 32 }}
        />
      </Button>
      <Menu
        id={"profileMenu"}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="1"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-log-out"
            >
              <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
              <polyline points="16 17 21 12 16 7"></polyline>
              <line x1="21" y1="12" x2="9" y2="12"></line>
            </svg>
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Menu>
    </Container>
  );
};

export default ProfileDropdown;

const Container = styled.div`
  margin-left: 4px;
  /* display: none;
    @media (min-width : 960px){
        display: inline-block;
    } */
`;
