import React from "react";
import { Route, Routes } from "react-router-dom";

const SearchUsers = React.lazy(() => import("./search"));
const BlueTickUsers = React.lazy(() => import("./BlueTickUsers"));
const GreenTickUsers = React.lazy(() => import("./GreenTickUsers"));

const Users = () => {
  return (
    <Routes>
      <Route
        path="search"
        element={
          <React.Suspense>
            <SearchUsers />
          </React.Suspense>
        }
      />
      <Route
        path="blue-tick"
        element={
          <React.Suspense>
            <BlueTickUsers />
          </React.Suspense>
        }
      />
      <Route
        path="green-tick"
        element={
          <React.Suspense>
            <GreenTickUsers />
          </React.Suspense>
        }
      />
    </Routes>
  );
};

export default Users;
