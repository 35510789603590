import { PaletteMode } from "@mui/material";
import { createTheme } from "@mui/material/styles";
const { palette } = createTheme();
const { augmentColor } = palette;

const createColor = (mainColor: string) => augmentColor({ color: { main: mainColor } });

declare module '@mui/material/styles' {
    interface Theme {
        background: {
            transparent: string;
            mention: string;
        };
        mention: {
            background: string;
            border: string;
            focus: string;
        },
        poll: {
            background: string;
        }
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        background?: {
            transparent?: string;
            mention?: string;
        };
        mention?: {
            background?: string;
            border?: string;
            focus?: string;
        },
        poll?: {
            background?: string;
        }
    }
}

export interface Colors {
    background: {
        default: string;
        card: string;
        header: string;
        disableButton: string;
        transparent: string;
        mention: string;
    },
    color: {
        default: string;
        title: string;
        subtitle: string;
    }
}

const theme = (mode: PaletteMode, colors: Colors | any) => createTheme({
    shape: {
        borderRadius: 6
    },
    palette: {
        mode,
        background: {
            default: colors?.background?.default,
            paper: colors?.background?.card
        },
        primary: createColor("#1d458a"),
        secondary: {
            main: "#7170701f"
        },
        // action: {
        //     // selected: "rgba(0, 0, 0, 0.024)",
        //     selected: "rgba(255, 255, 255, 0.024)",
        //     hover: "rgba(255, 255, 255, 0.024)"
        // }
    },
    typography: {
        allVariants: {
            color: colors?.color?.default
        },
        fontFamily: ["Mulish", "sans-serif"].join(","),
        h1: {
            fontSize: "1.5rem",
            fontWeight: 400
        },
        h2: {
            fontSize: "1.25rem",
            fontWeight: 'bold'
        },
        subtitle2: {
            lineHeight: "1rem",
            whiteSpace: "pre-wrap",
            overflowWrap: "break-word",
            wordWrap: "break-word",
            wordBreak: "break-word"
        },
        caption: {
            color: 'rgb(92, 112, 130)',
            fontSize: '0.875rem',
            lineHeight: 1.3333,
            fontWeight: 400
        },
        h3: {
            color: colors.color.title,
            fontSize: '1.875rem',
            fontWeight: 'bold'
        },
        h4: {
            fontSize: "1.5rem"
        },
        h5: {
            color: colors.color.subtitle,
            fontSize: '1.125rem',
            fontWeight: 'bold'
        },
        h6: {
            fontSize: '1rem',
            fontWeight: 'bold',
            whiteSpace: "pre-wrap",
            overflowWrap: "break-word",
            wordWrap: "break-word",
            wordBreak: "break-word"
        },
        button: {
            textTransform: "initial"
        },
        body2: {
            fontSize: "0.813rem",
            whiteSpace: "pre-wrap",
            overflowWrap: "break-word",
            wordWrap: "break-word",
            wordBreak: "break-word",
            // color: "#5c7082"
        }
    },
    mixins: {
        toolbar: {
            minHeight: 56,
            "@media (min-width:600px)": {
                minHeight: 56
            }
        }
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: colors.background.header,
                    color: colors?.color?.default
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                button: {
                    textTransform: "initial"
                },
                h4: {
                    fontSize: "1.5rem"
                }
            }
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    backgroundColor: "#fafafa",
                    color: "#000"
                }
            }
        },
        MuiButton: {
            defaultProps: {
                variant: "contained",
                size: "small",
                disableElevation: true
            },
            styleOverrides: {
                containedSecondary: {
                    color: colors?.color.default,
                },
                textPrimary: {
                    color: colors?.color.default
                },
                root: {
                    fontWeight: "bold",
                    "&.Mui-maxWidth": {
                        maxWidth: 120,
                    },
                    "&.Mui-disabled": {
                        "backgroundColor": "#7170701f"
                    }
                }
            }
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    color: colors.color.default
                }
            }
        },
        MuiTableCell: {
            defaultProps: {
                align: "left"
            },
            styleOverrides: {
                root: {
                    padding: 12
                }
            }
        },
        MuiCard: {
            defaultProps: {
                variant: "outlined"
            }
        },
        MuiBottomNavigation: {
            styleOverrides: {
                root: {
                    height: 48
                }
            }
        },
        MuiBottomNavigationAction: {
            styleOverrides: {
                root: {
                    minWidth: 60
                }
            }
        },
        MuiPaper: {
            defaultProps: {
                variant: "outlined",
                elevation: 0
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {

                }
            }
        },
        MuiButtonGroup: {
            styleOverrides: {
                grouped: {
                    fontSize: "0.725rem",
                    lineHeight: "1rem",
                    padding: 4
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    paddingTop: 0,
                    paddingBottom: 0,
                    "&:last-child": {
                        paddingBottom: 0
                    }
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                vertical: {
                    borderColor: colors.color.title
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    fontSize: "0.875rem"
                }
            }
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    borderLeft: "3px solid transparent",
                    fontWeight: 400,
                    "&.active": {
                        backgroundColor: "rgba(0,0,0,0.03)",
                        borderLeftColor: "rgb(221, 226, 255)",
                        color: "rgb(110, 140, 233)",
                        fontWeight: 600,
                    }
                }
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    minWidth: 32
                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontSize: "0.875rem",
                    lineHeight: "1.25rem",
                    color: "rgb(164, 166, 179)"
                }
            }
        },
        MuiCollapse: {
            styleOverrides: {
                entered: {
                    backgroundColor: "rgba(0,0,0,0.03)"
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    fontSize: "0.875rem",
                    lineHeight: "1rem"
                }
            }
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: "rgb(54, 55, 64)",
                    color: "rgb(164, 166, 179)",
                }
            }
        },
        MuiPagination: {
            defaultProps: {
                size: 'small'
            },
            styleOverrides: {
                root: {
                    margin: "16px auto"
                }
            }
        },
        MuiTable: {
            defaultProps: {
                size: 'small'
            }
        },
        MuiTextField: {
            defaultProps: {
                size: 'small',
                variant: "outlined",
                fullWidth: true
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontSize: "0.875rem"
                }
            }
        },
        MuiCheckbox: {
            defaultProps: {
                size: "small"
            },
            styleOverrides: {
                root: {
                    padding: 4
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    fontSize: 12
                }
            }
        }
    },
    // Custom
    background: {
        transparent: colors.background.transparent,
        mention: colors.background.mention
    },
    mention: {
        background: colors.mention.background,
        border: colors.mention.border,
        focus: colors.mention.focusColor
    },
    poll: {
        background: colors.poll.background
    }
})

export default theme;