import { useApolloClient } from "@apollo/client";
import { useAuth } from "react-oidc-context";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const LogoutOIDC = () => {
  const [searchParams] = useSearchParams();
  const sid = searchParams.get("sid"),
    iss = searchParams.get("iss");

  const client = useApolloClient();
  const auth = useAuth();

  useEffect(() => {
    if (sid === auth.user?.profile.sid && iss === auth?.user?.profile?.iss) {
      auth.signoutSilent().then(() => {
        client.resetStore();
      });
    }
  }, [auth, client, sid, iss]);
  if (auth?.isLoading) return <div>Please wait...</div>;
  return <div>Logout</div>;
};

export default LogoutOIDC;
