import React, { Fragment } from "react";
import { Paper, Stack, styled, Typography } from "@mui/material";
import HeaderWithOutSideMenu from "layouts/main/components/HeaderWithOutSideMenu";

const NotAuthenticated = () => {
  return (
    <Fragment>
      <HeaderWithOutSideMenu sx={{ width: "100%" }} />
      <CustomStack>
        <CustomPaper variant="outlined">
          <Typography variant="h4" sx={{ color: "#b71c1c" }}>
            401 - Access Denied
          </Typography>
          <Typography
            sx={{
              backgroundColor: "#FFCCD2",
              color: "#b71c1c",
              py: 2.5,
            }}
          >
            You do not have any admin privileges
          </Typography>
        </CustomPaper>
      </CustomStack>
    </Fragment>
  );
};

export default NotAuthenticated;

const CustomStack = styled(Stack)({
  height: "100vh",
  width: "100vw",
});

const CustomPaper = styled(Paper)({
  height: "auto",
  width: "min(100%, 540px)",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#FFCCD2",
  borderColor: "#b71c1c",
  padding: "32px",
});
