import React, { Suspense } from "react";
import { withAdminRoleRequired } from "config/auth";
import { keyRoleMap } from "config/side-menu-items";
import { Route, Routes } from "react-router-dom";

const Activities = React.lazy(() => import("./activities"));
const Events = React.lazy(() => import("./events"));
const Promotion = React.lazy(() => import("./promotion"));
const PushNotifications = React.lazy(() => import("./pushNotifications"));
const SmartFeed = React.lazy(() => import("./interstitials"));

const MarketingSettings = () => {
  return (
    <Routes>
      <Route
        path="activities"
        element={
          <Suspense fallback={<></>}>
            <Activities />
          </Suspense>
        }
      />
      <Route
        path="events"
        element={
          <Suspense fallback={<></>}>
            <Events />
          </Suspense>
        }
      />
      <Route
        path="promotion"
        element={
          <Suspense fallback={<></>}>
            <Promotion />
          </Suspense>
        }
      />
      <Route
        path="push-notifications"
        element={
          <Suspense fallback={<></>}>
            <PushNotifications />
          </Suspense>
        }
      />
      <Route
        path="smart-feed"
        element={
          <Suspense fallback={<></>}>
            <SmartFeed />
          </Suspense>
        }
      />
    </Routes>
  );
};

export default withAdminRoleRequired(MarketingSettings, {
  roles: keyRoleMap["Marketing Settings"],
});
