import * as React from "react";
import { NavLink, To, useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemText, { ListItemTextProps } from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {
  Accordion as MuiAccordion,
  AccordionProps,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Avatar,
  Stack,
  styled,
  ListItemButton,
  ListItemButtonProps,
  AccordionSummaryProps,
} from "@mui/material";
import imgLogo from "assets/images/logo.png";
import { checkAccessibility } from "constants/permission";
import { FC } from "react";
import menuItems from "config/side-menu-items";
import { ExpandMore } from "@mui/icons-material";
import { useGetRoles } from "config/auth";

interface SideMenuProps {
  handleDrawerToggle: any;
}

const SideMenu: FC<SideMenuProps> = ({ handleDrawerToggle }) => {
  const navigate = useNavigate();
  const roles = useGetRoles();

  return (
    <div>
      <Toolbar
        variant="dense"
        sx={{ paddingX: { xs: 1 } }}
        onClick={() => navigate("/")}
        style={{
          cursor: "pointer",
          position: "sticky",
          top: " 0px",
          zIndex: "1",
          backgroundColor: "rgb(54, 55, 64)",
        }}
      >
        <Avatar
          src={imgLogo}
          alt=""
          variant="rounded"
          sx={{ marginRight: { xs: 0.5 }, backgroundColor: "transparent" }}
        />
        <Stack direction="column">
          <Typography
            variant="h4"
            sx={{ fontSize: "1rem", color: "#fff", fontWeight: 500 }}
            noWrap
          >
            Admin Panel
          </Typography>
        </Stack>
      </Toolbar>
      <Divider />
      <List component="div" disablePadding>
        {menuItems.map((item) =>
          checkAccessibility(roles, item?.roles || []) ? (
            item.children ? (
              <Accordion key={item.text} elevation={0}>
                <AccordionSummary
                  expandIcon={<ExpandMore sx={{ color: "white" }} />}
                >
                  <ListItemText primary={item.text} />
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    padding: 0,
                    backgroundColor: "#24292e",
                    "& .MuiListItemText-primary": {
                      fontSize: "0.813rem",
                    },
                  }}
                >
                  <List component="div" disablePadding>
                    {item.children.map((child) =>
                      child.children ? (
                        <Accordion
                          key={child.text}
                          elevation={0}
                          sx={{ paddingLeft: 1.25 }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMore sx={{ color: "white" }} />}
                          >
                            <ListItemText primary={child.text} />
                          </AccordionSummary>
                          <AccordionDetails
                            sx={{
                              padding: 0,
                              backgroundColor: "#24292e",
                              "& .MuiListItemText-primary": {
                                fontSize: "0.813rem",
                              },
                            }}
                          >
                            <List component="div" disablePadding>
                              {child.children.map((child) => (
                                <SideMenuListItemButton
                                  key={child.text}
                                  to={child.path}
                                  primary={child.text}
                                  sx={{ paddingLeft: 3 }}
                                  onClick={handleDrawerToggle}
                                />
                              ))}
                            </List>
                          </AccordionDetails>
                        </Accordion>
                      ) : (
                        <SideMenuListItemButton
                          key={child.text}
                          to={child.path}
                          primary={child.text}
                          sx={{ paddingLeft: 3 }}
                          onClick={handleDrawerToggle}
                        />
                      )
                    )}
                  </List>
                </AccordionDetails>
              </Accordion>
            ) : (
              <SideMenuListItemButton
                key={item.text}
                to={item.path}
                primary={item.text}
                onClick={handleDrawerToggle}
              />
            )
          ) : !item?.roles ? (
            <SideMenuListItemButton
              key={item.text}
              to={item.path}
              primary={item.text}
              onClick={handleDrawerToggle}
            />
          ) : null
        )}
      </List>
    </div>
  );
};

export default SideMenu;

const SideMenuListItemButton: FC<
  ListItemButtonProps &
    Pick<ListItemTextProps, "primary"> & { to: To | undefined }
> = ({ primary, to, ...rest }) => {
  if (to)
    return (
      <ListItemButton
        {...rest}
        component={(props: ListItemButtonProps) => {
          return (
            <NavLink className={props.className} style={props.style} to={to}>
              {props.children as React.ReactNode}
            </NavLink>
          );
        }}
      >
        <ListItemText primary={primary} />
      </ListItemButton>
    );
  return null;
};

const Accordion = styled(MuiAccordion)<AccordionProps>({
  "&:first-of-type": {
    borderRadius: 0,
  },
  backgroundColor: "transparent",
  boxShadow: "none",
  border: "none",
  "&:before": {
    display: "none",
  },
  "&.Mui-expanded": {
    margin: 0,
  },
  "& .MuiAccordionSummary-root": {
    backgroundColor: "transparent",
  },
  "& .MuiAccordionDetails-root": {
    backgroundColor: "transparent",
  },
});

const AccordionSummary = styled(MuiAccordionSummary)<AccordionSummaryProps>({
  paddingLeft: 18,
  "&.MuiAccordionSummary-contentGutters": {
    margin: 0,
  },
  "&.Mui-expanded": {
    margin: 0,
    minHeight: 48,
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    margin: 0,
  },
});
