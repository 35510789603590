import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { ArrowOutward } from "@mui/icons-material";
import { useGetPendingScheduledJobsDataQuery } from "generated/graphql";
import { isEmpty } from "lodash-es";
import NoDataFound from "components/NoDataFound";
import styled from "@emotion/styled";

interface CardProps {
  title: string;
  route: string;
  timePeriod: any;
}

const PendingJobsCard: FC<CardProps> = ({ title, route, timePeriod }) => {
  const { data, loading } = useGetPendingScheduledJobsDataQuery({
    variables: {
      startDate: timePeriod.startDate,
      endDate: timePeriod.endDate,
    },
  });
  return (
    <Card
      sx={{
        flex: 1,
      }}
    >
      <CardContent>
        {" "}
        <StickyTypography variant="h5" pt={2} pb={2}>
          {title}
          <Link to={route}>
            <Tooltip title="Pendings" sx={{ fontSize: "1rem", float: "right" }}>
              <IconButton>
                <ArrowOutward sx={{ fontSize: "1rem", float: "right" }} />
              </IconButton>
            </Tooltip>
          </Link>
        </StickyTypography>
        {data?.getPendingScheduledJobsData?.map((jobs) => (
          <Box
            key={jobs?.jobToExecute}
            sx={{
              padding: "5px",
              display: "flex",
            }}
          >
            <Typography
              sx={{
                fontSize: "0.8rem",
                mr: "7px",
                display: "inline-block",
                backgroundColor: "text.secondary",
                borderRadius: "50%",
                color: "white",
                maxWidth: "25px",
                width: "100%",
                height: "25px",
                textAlign: "center",
                paddingTop: "3px",
              }}
            >
              {jobs?.count}
            </Typography>
            <Typography
              variant="h6"
              color="text.secondary"
              sx={{ fontSize: "0.8rem" }}
              noWrap
            >
              {jobs?.jobToExecute}
            </Typography>
          </Box>
        ))}
        {loading ? (
          <Stack justifyContent="center" alignItems="center" sx={{ margin: 1 }}>
            <CircularProgress size={24} />
          </Stack>
        ) : (
          isEmpty(data?.getPendingScheduledJobsData) && (
            <Box sx={{ position: "relative", top: "0" }}>
              <NoDataFound />
            </Box>
          )
        )}
      </CardContent>
    </Card>
  );
};

export default PendingJobsCard;

const StickyTypography = styled(Typography)`
  position: sticky;
  top: 0px;
  background-color: white;
  z-index: 1;
`;
