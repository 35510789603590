export const drawerWidth = 240;

export const meanings = (val: string | undefined) => ({
    isVisible: "Visible",
    isHidden: "Hidden",
    isUnsuspected: "Not Suspected",
    isSuspected: "Suspected",
    isSuspended: "Suspended",
    isNotSuspended: "Not Suspended"
})[val || ""] || "";

export const meaningColors = (val: string | undefined) => ({
    isvisible: "#099109",
    ishidden: "#c3c3c3",
    isunsuspected: "#099109",
    issuspected: "red",
    issuspended: "red",
    isnotsuspended: "#099109",
    abusive: "rgb(208 213 20)",
    hateful: "#3821C6",
    spam: "red",
    other: "#2AE7C5",
})[val?.toLocaleLowerCase() || ""] || "#000";