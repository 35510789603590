import React, { FC } from "react";
import ReactApexChart from "react-apexcharts";
import { useWindowSize } from "react-use";

interface PieChartProps {
  series: any;
  colors: any;
  title: any;
  lables: any;
}

const PieChart: FC<PieChartProps> = ({ series, colors, title, lables }) => {
  const width = useWindowSize().width;
  const labels = {
    series: series,

    options: {
      chart: {
        width: width <= 1600 ? 300 : width <= 600 ? 200 : 380,
        type: "pie",
      },
      labels: lables,

      colors: colors,
      title: {
        text: title,
      },
      legend: {
        position: width <= 1600 ? "bottom" : "right",
      },
      // responsive: [
      //   {
      //     breakpoint: 1600,
      //     options: {
      //       chart: {
      //         width: 300,
      //       },
      //       legend: {
      //         position: "bottom",
      //       },
      //     },
      //   },
      //   {
      //     breakpoint: 1480,
      //     options: {
      //       chart: {
      //         width: 300,
      //       },
      //       legend: {
      //         position: "bottom",
      //       },
      //     },
      //   },
      //   {
      //     breakpoint: 600,
      //     options: {
      //       chart: {
      //         width: 200,
      //       },
      //       legend: {
      //         position: "bottom",
      //       },
      //     },
      //   },
      // ],
    },
  };
  return (
    <div>
      <ReactApexChart
        options={labels.options as any}
        series={labels.series as any}
        type="pie"
        maxWidth={380}
        style={{ padding: "8px", marginTop: "20px" }}
        height={screen.width >= 1600 ? 220 : 270}
      />
    </div>
  );
};

export default PieChart;
