import { Colors } from "./common.theme";

export const colors: Colors|any = {
  background: {
    default: "#fafafa",
    card: "#ffffff",
    header: "#ffffff",
    disableButton: "#16181c",
    transparent: "rgba(0, 0, 0, 0.024)",
    mention: "#fff"
  },
  color: {
    default: "#131722",
    title: "#5c7082",
    subtitle: "#131722"
  },
  mention:{
    background: "#fff",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    focusColor: "#2196f3"
  },
  poll: {
    background: "rgba(0, 0, 0, 0.072)",
  }
}