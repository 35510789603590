import React, { Fragment } from "react";
import { Paper, Stack, styled, Typography } from "@mui/material";
import HeaderWithOutSideMenu from "layouts/main/components/HeaderWithOutSideMenu";

const RedirectToLoginPage = () => {
  return (
    <Fragment>
      <HeaderWithOutSideMenu sx={{ width: "100%" }} />
      <CustomStack>
        <CustomPaper variant="outlined">
          <Typography
            sx={{
              py: 2.5,
              textAlign: "center",
            }}
          >
            Redirecting you to the login page...
          </Typography>
        </CustomPaper>
      </CustomStack>
    </Fragment>
  );
};

export default RedirectToLoginPage;

const CustomStack = styled(Stack)({
  height: "100vh",
  width: "100vw",
});

const CustomPaper = styled(Paper)({
  height: "auto",
  width: "min(100%, 540px)",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  padding: "32px",
  border: "none",
});
